import React from "react";
import { graphql } from "gatsby";

import * as ResourceHub from "components/ResourceHub";
import * as ResourceHubPost from "components/ResourceHubPost";
import { ArticleMetaType, DefaultMetaType } from "modules/meta/types";
import { ContentType, BestPracticesType, TopicsType } from "../types";
import { FontType } from "components/Meta/constants";
import { HeroColour, HeroSize } from "components/Hero/Banner";
import { HeroCategory } from "components/Hero/CategoryBanner";
import { ImageHorizontalPosition } from "modules/images/constants";
import { NewsletterPopupType } from "modules/newsletter/types";
import { useArticleSchema, useArticleSchemaProps } from "modules/meta/schema/ArticleSchema";
import { useBreadcrumbs } from "modules/breadcrumbs/hooks";
import { useHeight } from "components/Header/hooks";
import { useNewsletterPopup } from "components/Newsletter/hooks/newsletterPopup";
import HeroBanner from "components/Hero/Banner";
import HeroCategoryBanner from "components/Hero/CategoryBanner";
import Layout from "components/Layout";
import Meta from "components/Meta";
import MyAxelosTeaser from "components/MyAxelosTeaser";

type ResourceHubPageProps = { data: QueryTypes; location: any };

type QueryTypes = {
  contentstackHeader: {
    sign_in_button: {
      href: string;
    };
    my_axelos_url: {
      href: string;
    };
  };
  contentstackNewsletterPopup: NewsletterPopupType;
  contentstackGlobalMeta: DefaultMetaType;
  postData: {
    edges: {
      node: {
        id: string;
        title: string;
        hero_title: string;
        slug: string;
        categorisation: {
          content_type: ContentType[];
          best_practices: BestPracticesType[];
          topics: TopicsType[];
          priority_score: number;
        };
        case_study: {
          background_colour: string;
          company_logo: {
            url: string;
          };
          product_logo: {
            url: string;
          };
        };
        meta: ArticleMetaType;
        newsletter: {
          show_popup_page: string;
        };
        images: {
          hero_image: {
            url: string;
          };
          hero_image_horizontal_position: ImageHorizontalPosition;
          featured_image: any;
        };
        intro_content: {
          intro_paragraph: string;
          display_table_of_contents?: boolean;
          table_of_contents_title?: string;
        };
        teaser_explanation: string;
      };
    }[];
  };
};

const ResourceHubPage: React.FC<ResourceHubPageProps> = ({ data, location }) => {
  /* Newsletter Popup */
  const newsletter = data?.contentstackNewsletterPopup;
  const page = data?.postData?.edges[0]?.node;
  const { breadcrumbs } = useBreadcrumbs(location);
  const { node } = data.postData.edges[0];
  const categories = node.categorisation;

  const contentType = categories?.content_type[0]?.title;
  const getTags = categories?.content_type.concat(categories?.topics.concat(categories?.best_practices));

  const heroImage = node?.images?.hero_image?.url && `${node?.images?.hero_image?.url}?format=jpg&auto=webp?quality=60`;
  const featuredImage =
    node?.images?.featured_image?.url && `${node?.images?.featured_image?.url}?format=jpg&auto=webp?quality=60`;
  const heroImageComponent = node?.images?.hero_image
    ? node?.images?.hero_image
    : node?.images?.featured_image
    ? node?.images?.featured_image
    : null;
  const heroBanner = heroImage ? heroImage : featuredImage ? featuredImage : null;
  const heroTitle = node.title ? node.title : "";
  const teaserLoginLink = {
    path: data?.contentstackHeader.my_axelos_url.href,
    category: node?.categorisation.content_type[0]?.slug,
    slug: node?.slug,
  };

  const { height, ref } = useHeight();

  useNewsletterPopup({
    config: newsletter?.config,
    copy: newsletter?.copy,
    pageOverride: page?.newsletter?.show_popup_page,
    submitted: newsletter?.submitted,
  });

  const articleSchema: useArticleSchemaProps = {
    author: node?.meta?.author[0]?.title,
    datePublished: node?.meta?.publication_date,
    description: node?.intro_content?.intro_paragraph,
    headline: heroTitle,
    image: heroBanner,
    location,
    timeRequired: node?.meta?.time_to_read ? node?.meta?.time_to_read : 0,
  };

  return (
    <Layout forwardedRef={ref}>
      <Meta
        defaultMeta={data.contentstackGlobalMeta}
        location={location}
        meta={data.postData.edges[0].node.meta}
        preloadFonts={[FontType.PRIMARY_REGULAR, FontType.SECONDARY_REGULAR]}
        preloadDesktopImage={[heroBanner]}
        schemaMarkup={useArticleSchema({ ...articleSchema })}
        twitterEnabled={true}
      />
      {contentType === HeroCategory.PRACTICE ||
      contentType === HeroCategory.CASE_STUDY ||
      contentType === HeroCategory.REPORT ||
      contentType === HeroCategory.WHITE_PAPER ? (
        <HeroCategoryBanner
          case_study_background_colour={node?.case_study?.background_colour}
          case_study_company_logo={node?.case_study?.company_logo}
          case_study_product_logo={node?.case_study?.product_logo}
          crumbs={breadcrumbs}
          hero_image={heroImageComponent}
          hero_title={node?.hero_title}
          image_horizontal_position={node?.images?.hero_image_horizontal_position}
          title={heroTitle}
          type={contentType}
        />
      ) : (
        <HeroBanner
          background_colour={HeroColour.MONOCHROME_5}
          content={`<h1>${node?.hero_title}</h1>`}
          crumbs={breadcrumbs}
          hero_breadcrumbs={true}
          hero_image={heroImageComponent}
          hero_size={HeroSize.SMALL}
          slogan_title={false}
          title={heroTitle}
        />
      )}
      <ResourceHubPost.Wrapper type={contentType}>
        <div className="container small">
          <ResourceHub.PopularSearch
            popularSearchTags={getTags}
            title={null}
            isPostPage={true}
            showActiveStyles={true}
          />
        </div>
        <div className="container small">
          <div>
            <ResourceHubPost.PublishInfo
              author={node?.meta?.author[0]}
              date={node?.meta?.publication_date}
              readTime={node.meta.time_to_read}
            />
            <ResourceHub.PopularSearch
              isPostPage={true}
              popularSearchTags={getTags}
              showActiveStyles={true}
              title={null}
            />
          </div>
          <div>
            <ResourceHubPost.Intro
              headerHeight={height}
              intro={node?.intro_content?.intro_paragraph}
              showTableOfContents={node?.intro_content?.display_table_of_contents}
              tableOfContentsTitle={node?.intro_content?.table_of_contents_title}
            />
            <MyAxelosTeaser text={node?.teaser_explanation} login={teaserLoginLink} />
          </div>
        </div>
      </ResourceHubPost.Wrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($id: String!) {
    contentstackHeader {
      sign_in_button {
        href
      }
      my_axelos_url {
        href
      }
    }
    contentstackNewsletterPopup {
      ...newsletterPopup
    }
    contentstackGlobalMeta {
      ...defaultMeta
    }
    postData: allContentstackResourceHubPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          hero_title
          slug
          newsletter {
            show_popup_page
          }
          categorisation {
            content_type {
              title
              id
              slug
            }
            best_practices {
              title
              id
              slug
              colour
            }
            topics {
              title
              id
              slug
            }
            priority_score
          }
          case_study {
            background_colour
            company_logo {
              alt_text
              logo {
                url
                title
              }
            }
            product_logo {
              alt_text
              logo {
                url
                title
              }
            }
          }
          meta {
            window_title
            meta_description
            canonical
            opengraph_title
            opengraph_description
            opengraph_type
            opengraph_url
            opengraph_image {
              url
            }
            twitter_title
            twitter_description
            twitter_image {
              url
            }
            time_to_read
            publication_date
            author {
              bio
              title
              image {
                url
              }
            }
          }
          images {
            hero_image {
              url
            }
            hero_image_horizontal_position
            featured_image {
              url
            }
          }
          intro_content {
            intro_paragraph
            display_table_of_contents
            table_of_contents_title
          }
          teaser_explanation
        }
      }
    }
  }
`;

export default ResourceHubPage;
