import React from "react";
import clsx from "clsx";

import TableOfContents from "../TableOfContents";
import RichText from "components/RichText";

import * as styles from "./styles.module.scss";

type ResourceHubPostIntroProps = {
  headerHeight: number;
  intro: string;
  showTableOfContents?: boolean;
  tableOfContentsTitle?: string;
};

const Intro: React.FC<ResourceHubPostIntroProps> = ({
  headerHeight,
  intro,
  showTableOfContents,
  tableOfContentsTitle,
}) => {
  const className = clsx(styles.resourceHubPostIntro, intro === "" && showTableOfContents === false && styles.empty);
  return (
    <div className={className}>
      {intro && <RichText content={intro} largeText={true} />}
      {showTableOfContents && (
        <TableOfContents headerHeight={headerHeight} tableOfContentsTitle={tableOfContentsTitle} />
      )}
    </div>
  );
};

export default Intro;
