import { useCallback, useEffect, useRef, useState } from "react";

export const useHeight = () => {
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLElement>(null);

  const handleResize = useCallback(() => {
    ref.current && setHeight(ref.current.getBoundingClientRect().height);
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return { height, ref };
};
