import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useHeadingsData } from "./hooks";

import * as Button from "components/Button";
import ChevronSVG from "modules/theme/icons/chevrons/button.svg";

import * as styles from "./styles.module.scss";
import { ButtonStyle, ButtonTarget } from "components/Button/constants";

type TableOfContentsProps = {
  headerHeight: number;
  tableOfContentsTitle?: string;
};

type HeadingsItemProps = {
  handleHeadingClick: (id: string) => void;
  id: string;
  title: string;
};

type SlicedHeadingsListProps = {
  end: number;
  handleHeadingClick: (id: string) => void;
  items: any;
  start: number;
};

export const HeadingsItem: React.FC<HeadingsItemProps> = ({ handleHeadingClick, id, title }) => {
  return (
    <li>
      <Button.CustomLink
        label={title}
        target={ButtonTarget.SELF}
        href={`#${id}`}
        onClick={(e) => {
          e.preventDefault();
          handleHeadingClick(id);
        }}
      />
    </li>
  );
};

export const SlicedHeadingsList: React.FC<SlicedHeadingsListProps> = ({ end, handleHeadingClick, items, start }) => {
  return (
    <ul>
      {items.slice(start, end).map((item: any, index: any) => (
        <HeadingsItem handleHeadingClick={handleHeadingClick} id={item.id} key={index} title={item.title} />
      ))}
    </ul>
  );
};

const TableOfContents: React.FC<TableOfContentsProps> = ({ headerHeight, tableOfContentsTitle }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { headings } = useHeadingsData();
  const [toggleButton, setToggleButton] = useState(
    ref.current && ref.current.getBoundingClientRect().bottom < headerHeight
  );
  const className = clsx(styles.tableOfContents, toggleButton && styles.showButton);

  const handleHeadingClick = (id: string) => {
    const headingElement = document.querySelector(`[id='${id}']`);
    headingElement !== null && headingElement.scrollIntoView({ behavior: "smooth" });
  };

  const handleBackToTopClick = (e: any) => {
    // e.preventDefault();
    const tableOfContentsElement = document.querySelector(`[id='TableOfContents']`);
    tableOfContentsElement !== null && tableOfContentsElement.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const hideBackToTop = () => {
      if (ref.current && ref.current.getBoundingClientRect().bottom < headerHeight) {
        setToggleButton(true);
      } else {
        setToggleButton(false);
      }
    };
    window.addEventListener("scroll", hideBackToTop);
    return () => {
      window.removeEventListener("scroll", hideBackToTop);
    };
  }, [toggleButton, ref]);
  return headings.length > 0 ? (
    <div id="TableOfContents" className={className} ref={ref}>
      <h3>{tableOfContentsTitle ? tableOfContentsTitle : "Table of Contents"}</h3>
      <div>
        <SlicedHeadingsList
          start={0}
          end={Math.round(headings.length / 2)}
          handleHeadingClick={handleHeadingClick}
          items={headings}
        />
        <SlicedHeadingsList
          start={Math.round(headings.length / 2)}
          end={headings.length}
          handleHeadingClick={handleHeadingClick}
          items={headings}
        />
      </div>
      <Button.CustomLink
        label="Back to Top"
        roundedCorner={true}
        target={ButtonTarget.SELF}
        onClick={(e: any) => handleBackToTopClick(e)}
        style={ButtonStyle.BRAND_AQUA}
      >
        <ChevronSVG />
      </Button.CustomLink>
    </div>
  ) : null;
};

export default TableOfContents;
