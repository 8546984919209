import useSiteMetadata from "modules/siteurl";
import { stripTags } from "modules/utils";

export type useArticleSchemaProps = {
  location: any;
  headline: string;
  image: string | null;
  description: string;
  author: string;
  datePublished: string;
  timeRequired: any;
};

export const useArticleSchema = ({
  location,
  headline,
  image,
  description,
  author,
  datePublished,
  timeRequired,
}: useArticleSchemaProps) => {
  const { siteUrl } = useSiteMetadata();
  return {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `${siteUrl}${location.pathname}`,
    },
    inLanguage: "en-GB",
    headline,
    description: stripTags(description),
    image,
    author: {
      "@type": "Person",
      name: author,
    },
    timeRequired,
    publisher: {
      "@type": "Organization",
      name: "Axelos",
      logo: {
        "@type": "ImageObject",
        url: `${siteUrl}/images/logos/axelos.svg`,
      },
    },
    datePublished,
  };
};
