import React from "react";
import clsx from "clsx";

import * as styles from "./styles.module.scss";

type ResourceHubPostWrapperProps = { type?: string };

const Wrapper: React.FC<ResourceHubPostWrapperProps> = ({ children }) => {
  const className = clsx(styles.resourceHubPostWrapper);
  return <div className={className}>{children}</div>;
};

export default Wrapper;
