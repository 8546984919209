import React from "react";
import LazyloadImage from "modules/lazyload/Image";

import * as styles from "./styles.module.scss";

type ResourceHubPostPublishInfoProps = {
  author: AuthorProps;
  date: string;
  readTime: any;
};

export type AuthorProps = {
  bio?: string;
  image?: {
    url: string;
  };
  title: string;
};

const PublishInfo: React.FC<ResourceHubPostPublishInfoProps> = ({ author, date, readTime }) => {
  const date_format = date && new Date(date);
  const published =
    date_format && date_format.toLocaleDateString("en-US", { day: "numeric", month: "long", year: "numeric" });
  return (
    <div className={styles.publishInfo}>
      {author && (
        <div className={styles.author}>
          {author.image?.url && (
            <LazyloadImage
              desktop={{ height: 88, width: 88, aspectRatio: "1:1" }}
              mobile={{ height: 88, width: 88, aspectRatio: "1:1" }}
              src={author.image.url}
              tagName="div"
            />
          )}
          <p className={styles.name}>
            Author&nbsp;&nbsp;
            <span>{author.title}</span>
          </p>
          {author.bio && <p className={styles.bio}>{author.bio}</p>}
        </div>
      )}
      <div className={styles.publish}>
        <p>
          <span>{published}</span>
          {readTime && <>&nbsp;|</>}
        </p>
        {readTime && (
          <p>
            &nbsp;
            <span>{readTime}</span>
            &nbsp;min read
          </p>
        )}
      </div>
    </div>
  );
};

export default PublishInfo;
