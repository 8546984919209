import React from "react";

import * as Button from "components/Button";
import * as styles from "./styles.module.scss"
import { ButtonStyle, ButtonTarget } from "components/Button/constants";

type MyAxelosTeaserTypes = {
  text: string;
  login: {
    path: string,
    category: string,
    slug: string,
  };
}

const MyAxelosTeaser: React.FC<MyAxelosTeaserTypes> = ({text, login}) => {
  const loginLink = `${login.path}/${login.category}/${login.slug}`;
  return (
    <div className={styles.myaxelosTeaser}>
      <p>{text}</p>
      <div>
        <div>
          <Button.PageLink
            label={'Subscribe'}
            roundedCorner={false}
            slug={'/for-professionals/my-axelos'}
            style={ButtonStyle.BRAND_AQUA}
            target={ButtonTarget.BLANK}
          />
        </div>
        <div>
          <Button.CustomLink
            href={loginLink}
            label={'Login'}
            roundedCorner={false}
            style={ButtonStyle.BRAND_AQUA}
            target={ButtonTarget.BLANK}
          />
        </div>
      </div>
    </div>
  );
};

export default MyAxelosTeaser;
