import { useEffect, useState } from "react";

export const useHeadingsData = () => {
  const [headings, setHeadings] = useState<any[]>([]);
  useEffect(() => {
    const headingElements = Array.from(document.querySelectorAll(".contentsHeadingTitle > h2"));
    const newHeadings = getHeadings(headingElements);
    setHeadings(newHeadings);
  }, []);
  return { headings };
};

export const getHeadings = (headingElements: any) => {
  const headings: any[] = [];
  headingElements.forEach((heading: any) => {
    const { innerText: title, id } = heading;
    headings.push({ id, title });
  });
  return headings;
};
